.App {
	height: 100vh;
}

.btn {
	border: 0;
	margin-right: 2px;
	padding: 8px 12px;
	margin-bottom: 10px;
	margin-left: 10px;
	background-color: rgba(255, 255, 255, 0.2);
	color: white;
}

.card-stack {
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
	margin: 0 auto;
	/* pointer-events: none; */
}

.card-stack.active {
	pointer-events: all;
}

.card {
	/* Add shadows to create the "card" effect */
	width: 207px;
	height: 300px;
	background-color: 'white';
	border-radius: 15px;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
}

.card svg {
	padding: 4px;
	box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	background-color: white;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
}

.card h1 {
	display: inline-block;
	margin: 0;
	padding: 0;
}

/* On mouse-over, add a deeper shadow */

h1,
h2,
h3,
h4,
div {
	color: white;
}

.bid-select {
	/* padding: 8px; */
	flex-basis: 100px;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
	transition: flex-basis 0.3s ease;
}

.bid-selected {
	/* padding: 8px; */
	flex-basis: 0px;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100vh;
}

.bid-button {
	width: 100px;
	height: 100px;
	margin: 4px;
	background-color: rgba(39, 25, 25, 0);
	border: 2px solid white;
	font-size: 36px;
	color: white;
	cursor: pointer;
}

.bid-button:disabled {
	opacity: 0.4;
}

.bid-button.selected {
	background-color: rgba(255, 255, 255, 0.1);
}

.bid-star {
	color: white;
	font-size: 36px;
	line-height: 1;
	opacity: 0.4;
}

.bid-star.trick {
	opacity: 1;
	transform: scale(1.2);
}

.bid-star.trick-bad {
	opacity: 1;
	color: black;
	transform: scale(1.2);
}

.bid-stars {
	display: flex;
	margin: 10px;
}

.player-view {
	display: flex;
}

.player-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.player-container.is-current {
	border-radius: 12px;
	border: 3px solid #e55934;
}

.player-info {
	display: flex;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 16px;
	align-items: center;
	border-radius: 64px;
	width: 190px;
}

.player-name {
	margin: 0 8px;
	max-width: 100px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.player-cards {
	margin-top: -10px;
	margin-left: -10px;
	width: 15px;
	height: 20px;
	box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.5);
	display: inline-block;
	background-color: white;
	border-radius: 2px;
}

.player-score {
	background-color: #e55934;
	padding: 8px;
	border-radius: 10px;
}

.player-card {
	display: flex;
}

.player-card .card {
	width: calc(250px * 0.69);
	height: 250px;
}

.avatar {
	width: 60px;
	height: 60px;
	border-radius: 30px;
	overflow: hidden;
}

.scoreboard {
	position: fixed;
	top: 0;
	max-height: 100px;
	right: 0;
}

.winner-display {
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: rgba(0, 0, 0, 0.8);
	padding: 24px;
	transform: translateX(-50%) translateY(-50%);
	z-index: 100;
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 16px;
	border: 7px solid #e55934;
}

.winner-display img {
	width: 100px;
	height: 100px;
	border-radius: 50px;
}

.winner-display h2 {
	color: #e55934;
	font-size: 50px;
	text-align: center;
	margin: 0;
}

.winner-display h2 b {
	color: white;
}
